<template>
  <div class="swc-content">
    <div class="type-title">
      <div class="billing-left"></div>
      <div class="billing-right">
        <div class="details" @click="toDingJin">
          <span class="iconfont"></span>预定管理
        </div>
        <div class="details" @click="toAddMember">
          <span class="iconfont"></span>创建客户
        </div>
        <div class="details" @click="toHaiKuan">
          <span class="iconfont icon-shoukuan"></span>还款
        </div>
        <div class="details-right" style="cursor: pointer" @click="toBilling">会员开单</div>
        <div class="details-right" style="cursor: pointer" @click="skBilling">散客开单</div>
      </div>
    </div>

    <div class="so-details">
      <div class="so-table" v-if="billingList.length>0" v-for="(item, index) in billingList">
        <div class="sot-header">
          <!--                <span class="sot-id">{{ item.serviceNum }}</span>-->
          <span class="sot-id">{{item.serviceName}}</span>
        </div>
        <div class="sot-username">
          <p v-if="item.memberName">{{ item.memberName }}</p>
          <p v-if="!item.memberName">散客</p>
        </div>
        <div class="sot-time">
          <span style="display: inline-block;">{{item.createTime}}</span>
        </div>
        <div style="width: 100%;border-top: silver solid 1px;margin-top: 3px;display: flex;flex-direction: column">
          <div style="display:flex;flex-direction: column;justify-items: center;justify-content: center;text-align: center;height: 165px">
            <span style="height: 20px;line-height: 20px;font-weight: bold;font-size: 18px">{{item.totalPrice}}</span>
            <span style="height: 20px;line-height: 20px">合计</span>
          </div>
        </div>
        <div class="btn-item" style="padding-top: 8px">
          <div class="qdan">
            <span style="background-color: #F799AA">申请优惠</span>
            <span style="background-color: #475683" @click="toLook(item)">详情</span>
          </div>
          <div class="qdan">
            <span style="background-color: #EC1B24"  @click="toDel(item)">删除</span>
            <span style="color: #1e1e1e" @click="takeOrder(item)">取单</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="lookVisible" width="450px">
      <div style="text-align: center;margin-top: -18px">
                <div class="sot-surface" style="margin: auto">
                  <div class="item-header">
                    <span class="ih-name">项目</span>
                    <span class="ih-price">价格</span>
                  </div>
                  <div class="sots-items">
                    <div class="sots-item" v-for="is in odItem.itemStr">
                      <span class="ih-name">{{is.itemName}}</span>
                      <span class="ih-price">¥{{is.price}}</span>
                    </div>
                    <div class="sots-item" v-for="ss in odItem.skuStr">
                      <span class="ih-name">{{ss.itemName}}</span>
                      <span class="ih-price">¥{{ss.price}}</span>
                    </div>
                    <div class="sots-item" v-for="sp in odItem.specStr">
                      <span class="ih-name">{{sp.itemName}}</span>
                      <span class="ih-price">¥{{sp.price}}</span>
                    </div>
                  </div>
<!--                  <div class="sots-hj">-->
<!--                    <span style="padding: 0px 10px;font-weight: bold">合计:</span>-->
<!--                    <span style="color:red;">¥{{item.totalPrice}}</span>-->
<!--                  </div>-->
<!--                  <div class="sots-fw">-->
<!--                    <span>结束服务</span>-->
<!--                  </div>-->
                </div>
      </div>
    </el-dialog>
    <!-- 删除单子 -->
    <el-dialog title="删除订单" :visible.sync="delVisible" width="30%">
      <div style="text-align: center;margin-top: -18px">
        是否删除订单?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delVisible=false">取 消</el-button>
        <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
      </span>
    </el-dialog>

    <TabMemberList
      v-if="mbVisible"
      :mbVisible="mbVisible"
      @setMember="setMember"
      @setMemberCard="setMemberCard"
      @closeML="closeML"
    />

    <TabSaveMember
            v-if="smVisible"
            :smVisible="smVisible"
            @setMember="setMember"
            @closeSM="closeSM"
    />

  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";
  import TabMemberList from "../common/member/memberList";
  import TabSaveMember from "../common/member/saveMember";
  export default {
    name: "dashboard",
    components: {
      TabMemberList,
      TabSaveMember,
    },
    data() {
      return {
        checked: true,
        input2: "",

        radio: "",

        item: {},
        billingList: [],
        memberArrearsList: [],

        remarks: "",
        delVisible: false,

        odItem:{},
        lookVisible: false,

        assetsVisible: false,
        mbVisible: false,
        times: [],

        smVisible: false,
      };
    },
    async created() {
      this.getBillingList();
    },
    methods: {
      //创建客户
      toAddMember() {
        this.smVisible = true;
      },
      closeSM() {
        this.smVisible = false;
      },

      //会员开单
      toBilling() {
        this.mbVisible = true;
      },

      //散客开单
      skBilling() {
        this.$router.push(`/cashiers/billing`);
      },

      //定金管理
      toDingJin() {
        this.$router.push(`/deposits/deposit`);
      },

      toHaiKuan() {
        this.$router.push('/cashiers/arrears');
      },
      // async endService(item) {
      //   let data = {
      //     id: item.id,
      //   };
      //   let res = await this.$post("/api/endService", data);
      //   if (res.code == 200) {
      //     this.$message.success("服务" + res.status + ",时长" + res.time);
      //     this.getBillingList();
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // },


      //选择会员信息
      setMember(item) {
        this.closeML();
        this.$router.push(`/cashiers/billing?memberId=` + item.id);
        this.closeSM();
      },

      //选择会员卡信息
      setMemberCard(mb,mc) {
        this.$router.push(`/cashiers/billing?memberId=` + mb.id+'&mcId='+mc.id);
        this.closeML();
      },

      closeML() {
        this.mbVisible = false;
      },

      toLook(item) {
        this.odItem = item;
        this.lookVisible = true;
      },

      toDel(item) {
        this.item = item;
        this.delVisible = true;
      },

      async delMethod() {
        let data = {
          id: this.item.id,
        };
        let res = await this.$post("/sw/deleteBilling", data);
        if (res.code == 200) {
          this.getBillingList();
          this.delVisible = false;
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

      async getBillingList() {
        let data = {
          token: getToken(),
          orderStatus: "3",
        };
        let res = await this.$get("/sw/getBillingList", data);
        if (res.code == 200) {
          this.billingList = res.orders;
          this.billingList.forEach(item => {
            item.itemStr = JSON.parse(item.itemStr)
            item.skuStr = JSON.parse(item.skuStr)
            item.specStr = JSON.parse(item.specStr)
          })
        }
      },


      takeOrder(item) {
        this.$router.push(
          `/cashiers/reorder?orderId=` +
          item.id +
          "&serviceNum=" +
          item.serviceNum
        );
      },
    },

  };
</script>


<style lang="scss" scoped>

  .swc-content {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: scroll;
  }

  .type-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    background-color: #f5f5f5;
  }

  .billing-left {
    width: 50%;
  }

  .billing-right {
    padding-right: 5px;
    display: flex;
    text-align: center;

    .details {
      width: 100px;
      height: 60px;
      line-height: 60px;
      background-color: #fff;
      border: #d2d2d2 solid 1px;
      cursor: pointer;
      .iconfont {
        font-size: 22px;
        margin-right: 5px;
      }
    }

    .details:hover {
      background-color: #f5f5f5;
    }

    .details-right {
      width: 130px;
      height: 60px;
      background-color: #555555;
      color: #fff;
    }
  }

  .so-content {
    height: 40px;
    line-height: 40px;
  }

  .so-details {
    width: 100%;

    .so-table {
      float: left;
      margin: 10px;
      width: 238px;
      height: 325px;
      border: 1px solid #dccdb6;
      padding-right: 8px;
      padding-left: 8px;

      .sot-id {
        display: inline-block;
        font-size: 16px;
        color: red;
        font-weight: bold;
        height: 20px;
        margin-top: 3px;
      }

      .yuan {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #f0f0f0;
        border: 1px #d1d1d1 solid;
        display: inline-block;
      }
    }
  }

  .sot-header {
    height: 20px;
    margin-top: 2px;
  }

  .sot-username p {
    font-size: 13px;
    color: #222222;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
  }

  .sot-time {
    color: #515151;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
  }

  .table-name p {
    color: #505050;
    height: 25px;
    font-size: 13px;
  }

  .sot-surface {
    width: 236px;
    height: 200px;
    margin-top: 10px;
    border: #d2d2d2 solid 1px;
    font-size: 10px;

    .item-header {
      display: flex;
      flex-direction: row;
      text-align: center;
      height: 20px;
      line-height: 20px;
      font-weight: bold;
      border-bottom: #d2d2d2 solid 1px;
    }

    .item-header .ih-name {
      width: 67%;
    }

    .item-header .ih-price {
      border-left: #d2d2d2 solid 1px;
      width: 33%;
    }

    .sots-items {
      height: 138px;
      overflow: scroll;
    }

    .sots-items .sots-item {
      display: flex;
      flex-direction: row;
      text-align: center;
      height: 20px;
      line-height: 20px;
      border-bottom: #d2d2d2 solid 1px;
    }

    .sots-item .ih-name {
      width: 67%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .sots-item .ih-price {
      border-left: #d2d2d2 solid 1px;
      width: 33%;
    }

    .sots-hj {
      border-top: #d2d2d2 solid 1px;
      line-height: 20px;
      height: 20px;
    }

    .sots-fw {
      border-top: #d2d2d2 solid 1px;
      line-height: 20px;
      height: 20px;
    }
  }

  .footer-shanchu {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #eeeeee;
    line-height: 30px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .take-order {
    width: 50px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: #eb2742;
    color: #fff;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 130px;
  }

  .sot-surface {
    table tbody {
      display: block;
      height: 77px;
      overflow-y: scroll;
    }

    table thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
  .btn-item{
    display: flex;
    flex-direction: column;
    height: 60px;
  }
  .qdan{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .qdan span{
    width: 78px;
    text-align: center;
    height: 22px;
    line-height: 22px;
    margin: 5px;
    border: #B3B3B3 solid 1px;
    cursor: pointer;
    color: #FFFFFF;
  }
</style>
